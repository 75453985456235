.companyDetailPage,
.subCompanyDetailPage {
  .image-preview-component {
    display: block;

    img {
      padding: 15px 15px 5px;
      max-width: 100%;
      height: 100px;
      object-fit: contain;
    }
  }
}

.image-preview-component {
  @include newTabIconIcon;

  position: relative;
  display: inline-block;

  @mixin max() {
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
  }

  .component {
    &-modal {
      @include max;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2500;
      position: fixed;
    }

    &-overlay {
      @include max;
      position: absolute;
      background-color: $color-dark-transparent;
    }

    &-thumbnail {
      cursor: pointer;
      max-width: 100px;
      margin: 0 20px;
    }

    &-img-open {
      position: relative;
      max-height: 90vh;
      max-width: 90vw;
      min-height: 60vh;
      min-width: 60vw;
    }

    $icon-size: 22px;
    $icon-padding: 6px;
    $icon-hover: 6px;

    &-icon-class {
      height: $icon-size;
      width: $icon-size;
      transition: width 0.5s, height 0.5s;
      color: $color-text-dark4;
    }

    $hover-size: $icon-size + $icon-hover;
    $full-size: $hover-size + $icon-padding * 2;

    &-icon-container {
      width: $full-size;
      height: $full-size;
      margin-bottom: 15px;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-icon-button {
      box-shadow: 0 9px 20px -6px $color-border-shadow6;
      border-radius: 50%;
      padding: $icon-padding;
      background: $color-white;
      cursor: pointer;
      border: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      svg {
        display: block !important; // scss-lint:disable ImportantRule
      }

      &:hover {
        svg {
          height: $hover-size !important; // scss-lint:disable ImportantRule
          width: $hover-size !important; // scss-lint:disable ImportantRule
        }
      }
    }

    &-action-buttons {
      position: fixed;
      right: 30px;
      top: 30px;
      z-index: 1000;
    }
  }
}
